import axios from "axios";
import Actions from "../Type";
import toast from 'react-hot-toast';

function reg(regObject) {
    const data = JSON.stringify(regObject);
    // console.log(data);
    var config = {
      method: "post",
      url: "https://curly-forest-26450.botics.co/rest-auth/registration/",
      headers: {
        "Content-Type": "application/json",
      },

      data: data,
    };

    return axios(config)
}

export default reg;
