import React, { useState, useEffect } from "react";
import Button from "../components/Button";
import Card from "../components/Card";

import Actions from "../Store/Type";
import { auth } from "../Store/Actions/Auth";
import ConfigureStore from "../Store/Store";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import Header from "../components/Header";
import { userUpdate } from "../Store/Actions/User";

import axios from "axios";

const Nexusloan = (props) => {
  const history = useHistory();
  const [invalid, setInvalid] = useState(false);
  const [user_Details, setUser_Details] = useState([]);

  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();

  const [nexus, setNexus] = useState(null);

  useEffect(() => {
    const _nexus = window.Nexus({
      nexus_key:
        "4cf0d535a934aba6575a2db6121dfffd61587dd60f17cc959d1e2c6a0de4d31d",
      // borrower: "72edfd83-eca2-4383-a015-ff7a230504c2",
      borrower: props.borrower_id || "72edfd83-eca2-4383-a015-ff7a230504c2",
    });
    setNexus(_nexus)
  }, [])

  useEffect(() => {
    setRedirect(props.redirectTo);
    if (redirect === "/nexusloan") {
      history.push(props.redirectTo);
    }
  }, [props.redirectTo, redirect]);

  const handleNexus = () => {
    window.Nexus.enroll({
      // plan: 'repaye',
      onSuccess: function (result) {

        console.log("onSuccess results :: ", result);
        dispatch(
          userUpdate({
            user: {
              // name: props.user,
              borrower_id: result.result.enrollment.uuid,
              // email: props.email,
            },
          })
        );

      },
      onExit: function (result) {
        console.log("On Exit results :: ", result);
        switch (result.status) {
          case "incomplete":
            break;
          case "illegal":
            break;
          case "error":
            break;
        }
      },
    });
  };


  const handleLinkNexus = () => {
    window.Nexus.link({
      onSuccess: linkSuccess,
      onExit: linkExit,
    });
  };

  function linkSuccess(response) {
    console.log("Servicers linked");
    console.log("Link response :: ", response);
  }

  function linkExit(response) {
    console.log("link exit response :: ", response);
    switch (response.status) {
      case "incomplete":
        break;
      case "illegal":
        break;
      case "error":
        break;
    }
  }
  // const data = [
  //   {
  //     header: "Linking Service Provider",
  //     description:
  //       "First of all you need to link your service provider account to become eligible for the plans to enroll.",
  //     button: "Link",
  //     onClick: "handleLinkNexus",
  //   },
  // ];


  function assessSuccess(response) {
    console.log("Assess complete");
    console.log("assessSuccess Response :: ", response);

  }

  function assessExit(response) {
    console.log("AssessExit Response :: ", response);
    switch (response.status) {
      case "incomplete":
        break;
      case "illegal":
        break;
      case "error":
        break;
    }
  }

  return (
    <div className="root-one">
      <Header />


      <div className="login-root-container rounded-2xl">
        <div className="flex justify-end m-2"></div>
        <h1 className="text-2xl font-semibold">Linking Service Provider</h1>
        <br />
        {/* <p>Description:</p> */}
        <p>
          First of all you need to link your service provider account to become
          eligible for the plans to enroll.
        </p>

        <br />
        <Button
          buttonStyle="btn--major--solid"
          buttonSize="btn--small"
          onClick={handleLinkNexus}
        >
          Link
        </Button>
      </div>
      <div className="login-root-container rounded-2xl">
        <h1 className="text-2xl font-semibold">Enroll for the Plan</h1>
        <br />
        {/* <p>Description:</p> */}

        <p>
          After Linking the accounts you need to fill some personal details and
          then you will be provided with the plans for which you are eligible.
        </p>
        <br />

        <Button
          className="ml-20"
          buttonStyle="btn--major--solid"
          buttonSize="btn--small"
          onClick={handleNexus}
        >
          Enroll
        </Button>
        {/* &nbsp; &nbsp; */}
        {/* <Button
          buttonStyle="btn--major--solid"
          buttonSize="btn--small"
          onClick={handleAssessNexus}
        >
          Assess
        </Button> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectTo: state.auth.redirectTo,
    invalidUser: state.auth.invalidUser,
    accessToken: state.auth.accessToken,
    loanContext: state.auth.loanContext,
    borrower_id: state.user_update.borrower_id,

    user: state.auth.user,
    email: state.auth.email,

  };
};

export default connect(mapStateToProps, { auth })(Nexusloan);
