import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import { auth } from "../Store/Actions/Auth";
import { connect } from "react-redux";
import Button from "../components/Button";
import Header from "../components/Header";
import "./clientaccount.css";
import NewPagination from "../components/Pagination";

const ClientList = (props) => {
  const history = useHistory();

  const [clients, setClients] = useState([]);
  useEffect(() => {
    if (props.isType === "student") {
      history.push("/studenthomepage");
    }
  }, []);

  useEffect(() => {
    var config = {
      method: "get",
      url: "https://curly-forest-26450.botics.co/api/v1/user/clients/",
      headers: {
        Authorization: `Token ${props.accessToken}`,
      },
    };
    axios(config).then((res) => {
      setClients(res.data);
    });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  clients.reverse()
  const currentPosts = clients.slice(indexOfFirstPost, indexOfLastPost);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    console.log("all clients ==--== ", clients);
  }, [clients])

  return (
    <div className="client-root-one">
      <Header />
      <div className="client-root-container">
        <div className="pb-4 pt-4 flex items-center justify-between">
          <h1 className="text-2xl font-semibold">Client Lists</h1>

          {props.accessToken ? (
            <Link to="/addclient">
              <Button buttonSize="btn--small">Add client</Button>
            </Link>
          ) : null}
        </div>

        <table class="table">
          <thead>
            <tr>
              <th scope="col" style={{ background: "rgb(245,245,245)" }}>
                Client Name
              </th>
              <th scope="col" style={{ background: "rgb(245,245,245)" }}>
                Trustee
              </th>
            </tr>
          </thead>
          <tbody>
            {currentPosts.map((item) => {
              return (
                <tr>
                  <td style={{ color: "gray" }}>
                    <div className="flex justify-items-start space-x-6">
                      <Link
                        className="links flex justify-items-start space-x-6"
                        to={`/clientinfo?client_first=${item.first_name ?? "First Name"
                          }&client_last=${item.last_name ?? "Last Name"
                          }&client_city=${item.city ?? "City"}&client_street=${item.street ?? "Street"
                          }&client_country=${item.country ?? "Country"
                          }&client_state=${item.state ?? "State"}&client_postal=${item.postal ?? "Postal"
                          }&client_email=${item.email ?? "Email"}&client_phone=${item.phone ?? "Phone"
                          }&trustee_name=${item.trustee != null ? item.trustee.name : "trustee_name"
                          }&trustee_phone=${item.trustee != null ? item.trustee.phone_no : "trustee_phone"
                          }&trustee_email=${item.trustee != null ? item.trustee.email : "trustee_mail"
                          }&trustee_country=${item.trustee != null ? item.trustee.country : "Trustee_Country"}&trustee_add=${item.trustee != null ? item.trustee.address : "trustee_address"
                          }`}
                      >
                        <p style={{ width: 200 }} className="cursor-pointer">{`${item.first_name} ${item.last_name}`}</p>
                      </Link>
                      <p className="text-basic font-semibold flex-1 text-center cursor-pointer">
                        SEND INVITE
                      </p>
                    </div>
                  </td>
                  <td style={{ color: "gray" }}>
                    <div className="flex justify-items-start space-x-6">
                      <p style={{ width: 200 }} className="cursor-pointer">{`${item.trustee ? item.trustee.name : "trustee_name"}`}</p>
                      <p className=" text-basic font-semibold flex-1 text-center cursor-pointer">
                        SEND INVITE
                      </p>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <NewPagination
          postsPerPage={postsPerPage}
          totalPosts={clients.length}
          paginate={paginate}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    isType: state.auth.isType,
  };
};

export default connect(mapStateToProps, { auth })(ClientList);
