import React,{useState, useEffect} from "react";
import { Route, Redirect, Switch,useHistory } from "react-router-dom";
import "./Routes.css";

import AddClient from "../pages/AddClient";
import ClientList from "../pages/ClientList";
import HomePage from "../pages/HomePage";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import LoginLoan from "../pages/LoginLoan";
import PaymentPage from "../pages/PaymentPage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Setting from "../pages/Setting";
import Signup from "../pages/Signup";
import SubscriptionPage from "../pages/SubscriptionPage";
import TermsOfService from "../pages/TermsOfService";
import Header from "../components/Header";
import Accounts from "../pages/Accounts";
import LawFirms from "../pages/LawFirms";
import Users from "../pages/Users";
import LawHomePage from "../pages/LawHomePage";
import SubscriberList from "../pages/SubscriberList";
import SavedForms from "../pages/SavedForms";
import StudentHomePage from "../pages/StudentHomePage";
import ResetPassword from "../pages/ResetPassword";
import ResetPass1 from "../pages/ResetPass1";
import ResetPass2 from "../pages/ResetPass2";
import ClientAccountInfo from "../pages/ClientAccountInfo";
import PaymentStripe from "../pages/PaymentStripe";
import SignupLaw from "../pages/SignupLaw";
import Nexusloan from "../pages/Nexusloan";
import { connect } from "react-redux";
import { auth } from "../Store/Actions/Auth";

const Routes = (props) => {

  const history = useHistory()

  useEffect(() => {
    if(props.accessToken === null) {
      history.push("/login")

    }

    else if (props.isType === "law_firm") {
      history.push("/lawhomepage")
    }

    else if (props.isType === "student") {
      history.push("/studenthomepage")
    }

    else {
      return;
    }

  }, [props.accessToken, props.isType])

  return (
    <div className="">
      <Route exact path="/">
        <Login />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/signup">
        <Signup />
      </Route>
      <Route exact path="/signuplaw">
        <SignupLaw />
      </Route>
      <Route exact path="/loginloan">
        <LoginLoan />
      </Route>

      {/*All Admin routes */}
      <Route exact path="/logout">
        <Logout />
      </Route>

      <Route exact path="/admin">
        <HomePage />
      </Route>
      <Route path="/lawfirms">
        <Header />
        <LawFirms />
      </Route>
      <Route path="/users">
        <Header />
        <Users />
      </Route>
      <Route path="/accounts">
        <Header />
        <Accounts />
      </Route>
      <Route path="/subscription">
        <SubscriptionPage />
      </Route>
      <Route path="/subscriberList">
        <SubscriberList />
      </Route>

      {/*All users settings routes */}
      <Route path="/nexusloan">
        <Nexusloan />
      </Route>
      <Route path="/setting">
        <Setting />
      </Route>
      <Route path="/forgot-password">
        <ResetPassword />
      </Route>
      {/*<Route path="/resetpass1">*/}
      {/*  <ResetPass1 />*/}
      {/*</Route>*/}
      {/*<Route path="/resetpass2">*/}
      {/*  <ResetPass2 />*/}
      {/*</Route>*/}
      <Route exact path="/terms">
        <TermsOfService />
      </Route>
      <Route exact path="/privacy">
        <PrivacyPolicy />
      </Route>

      <Route path="/payment">
        <PaymentPage />
      </Route>
      <Route path="/paymentstripe">
        <PaymentStripe />
      </Route>
      <Route exact path="/lawhomepage">
        <LawHomePage />
      </Route>
      <Route exact path="/clientlist">
        <ClientList />
      </Route>
      <Route exact path="/addclient">
        <AddClient />
      </Route>
      <Route exact path="/clientinfo">
        <ClientAccountInfo />
      </Route>
      <Route exact path="/savedforms">
        <Header />
        <SavedForms />
      </Route>

      <Route exact path="/studenthomepage">
        <StudentHomePage />
      </Route>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectTo: state.auth.redirectTo,
    invalidUser: state.auth.invalidUser,
    accessToken: state.auth.accessToken,
  };
};

export default connect(mapStateToProps, { auth })(Routes);

