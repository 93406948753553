import axios from "axios";
import Actions from "../Type";
import toast from 'react-hot-toast';

export function userUpdate(userObject) {
  return async (dispatch, getState) => {
    const token = getState().auth.accessToken;

    var data = JSON.stringify(userObject);



    var config = {
      method: "PUT",
      url: "https://curly-forest-26450.botics.co/api/v1/user/update-user/",
      headers: {
        "Content-Type": "application/json",
        // "X-CSRFToken": "14eb0c718c5e4761a5e7c10e926b6e073abac87a",

        Authorization: "Token " + token,

      },

      data: data,
    };

    // console.log(config);

    await axios(config)
      .then(function (response) {
        console.log(response);

        dispatch({
          type: Actions.UPDATE_SUCCESS,
          payload: userObject,
        });
      })
      .catch(function (error) {
        // console.log("Error while logging in ==--== ", error.response.data.non_field_errors[0]);
        if (
          error.response.data.non_field_errors[0] != null &&
          error.response.data.non_field_errors[0] != undefined
        ) {
          toast("Failed \n" + error.response.data.non_field_errors[0]);
        }
        dispatch({
          type: Actions.UPDATE_FAILED,
        });
      });
  };
}
