import { useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom";
import { auth } from "../Store/Actions/Auth";
import { connect } from "react-redux";
import toast from 'react-hot-toast';
import Button from "./Button";
import Header from "./Header";
import axios from "axios";
import ScreenLoader from './ScreenLoader';
import "../pages/loginloan.css";

function SubscriptionCard(props) {
  const history = useHistory();
  const [pricePlans, setPricePlans] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    var config = {
      method: "get",
      url: "https://curly-forest-26450.botics.co/api/v1/subscriptions/plan-prices/",
      headers: {
        Authorization: `Token ${props.accessToken}`,
      },
    };
    axios(config).then((res) => {
      setPricePlans(res.data)
      setLoading(false)
    });
  }, []);

  const handleSelectedItem = (item, index) => {
    setSelectedIndex(index)
    localStorage.setItem("pricePlanID", item.id);
    localStorage.setItem("pricePlanAmount", item.unit_amount);
  }

  const hanldeProceed = () => {
    if (selectedIndex != -1) {
      history.push("/payment");
    } else {
      toast("Please Select a Price Plan!")
    }
  }

  return (
    <div className="loginloan-root-one">
      {loading && <ScreenLoader />}
      <Header />
      <div className="loginloan-root-container rounded-2xl">
        <div className="flex-col mb-10">
          <h3 className="text-2xl font-semibold flex items-center justify-center">
            Subscription
          </h3>
        </div>

        {pricePlans?.data?.map((item, index) => (
          <div className="relative space-x-10 m-3">
            <input
              checked={(selectedIndex == index && selectedIndex != -1) ? true : false}
              className="absolute top-0 left-0 h-6 w-6"
              type="checkbox"
              value={item.recurring.interval}
              onChange={(e) => handleSelectedItem(item, index)}
            />
            <label className="text-xl font-semibold pb-5">
              {item.unit_amount} {item.currency} per {item.recurring.interval}
            </label>
          </div>
        ))}

        {pricePlans != null &&
          <div className="flex justify-center">
            <Button onClick={hanldeProceed} >Proceed</Button>
          </div>
        }
      </div>
    </div>
  );

}

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
  };
};

export default connect(mapStateToProps, { auth })(SubscriptionCard);