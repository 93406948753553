import React, { useState, useEffect } from 'react';
import {
     useStripe,
     useElements,
     CardNumberElement,
     CardExpiryElement,
     CardCvcElement
} from '@stripe/react-stripe-js';
import { stripePaymentMethodHandler } from './script';

const CARD_ELEMENT_OPTIONS = {
     style: {
          base: {
               lineHeight: "27px",
               color: "#212529",
               fontSize: "1.1rem",
               "::placeholder": {
                    color: "#aab7c4",
               },
          },
          invalid: {
               color: "#fa755a",
               iconColor: "#fa755a",
          },
     },
};

export default function CheckoutForm(props) {
     const [loading, setLoading] = useState(false);
     const [errorMsg, setErrorMsg] = useState('');
     const [name, setName] = useState('');
     const [email, setEmail] = useState('');
     const pricePlanId = localStorage.getItem('pricePlanID')
     const pricePlanAmount = localStorage.getItem('pricePlanAmount')

     const stripe = useStripe();
     const elements = useElements();

     const handleSubmit = async (event) => {
          event.preventDefault();
          if (!stripe || !elements) {
               return;
          }

          setLoading(true);
          setErrorMsg('');

          const paymentMethodObj = {
               type: 'card',
               card: elements.getElement(CardNumberElement),
               billing_details: {
                    name,
                    email
               },
          };
          const paymentMethodResult = await stripe.createPaymentMethod(paymentMethodObj);

          stripePaymentMethodHandler({
               result: paymentMethodResult,
               pricePlanId: pricePlanId
          }, handleResponse);
     };

     const handleResponse = response => {
          setLoading(false);
          if (response.error) {
               console.log("error response ==--== ", typeof response.error === 'string' ? response.error : response.error.message);
               setErrorMsg(typeof response.error === 'string' ? response.error : response.error.message);
               return;
          }
          props.setPaymentCompleted(response.success ? true : false);
     };

     return (
          <React.Fragment>
               <h4 className="d-flex justify-content-between align-items-center mb-3">
                    <h2 className="text-2xl font-bold">Stripe</h2>
               </h4>
               <form onSubmit={handleSubmit}>

                    <div className="row">
                         <div className="col-md-6 mb-3">
                              <label htmlFor="cc-name">Name on card</label>
                              <input
                                   id="cc-name"
                                   type="text"
                                   className="form-control"
                                   value={name}
                                   onChange={e => setName(e.target.value)}
                              />
                         </div>
                         <div className="col-md-6 mb-3">
                              <label htmlFor="cc-email">Email</label>
                              <input
                                   id="cc-email"
                                   type="text"
                                   className="form-control"
                                   value={email}
                                   onChange={e => setEmail(e.target.value)}
                              />
                         </div>
                    </div>

                    <div className="row">
                         <div className="col-md-12 mb-3">
                              <label htmlFor="cc-number">Card Number</label>
                              <CardNumberElement
                                   id="cc-number"
                                   className="form-control"
                                   options={CARD_ELEMENT_OPTIONS}
                              />
                         </div>
                    </div>

                    <div className="row">
                         <div className="col-md-6 mb-3">
                              <label htmlFor="expiry">Expiration Date</label>
                              <CardExpiryElement
                                   id="expiry"
                                   className="form-control"
                                   options={CARD_ELEMENT_OPTIONS}
                              />
                         </div>
                         <div className="col-md-6 mb-3">
                              <label htmlFor="cvc">CVC</label>
                              <CardCvcElement
                                   id="cvc"
                                   className="form-control"
                                   options={CARD_ELEMENT_OPTIONS}
                              />
                         </div>
                    </div>

                    <hr className="mb-4" />
                    <button className="btn btn-dark w-100" type="submit" disabled={loading}>
                         {loading ? <div className="spinner-border spinner-border-sm text-light" role="status"></div> : `PAY ` + pricePlanAmount}
                    </button>
                    {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
               </form>
          </React.Fragment>
     );
}