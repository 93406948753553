import {useEffect} from "react";
import {useDispatch} from "react-redux";
import Actions from "../Store/Type";

const Logout = () => {
  const dispatch = useDispatch()
  const logout = () => {
    dispatch({
      type: Actions.LOGOUT,
    });
  };

  useEffect(() => {
    logout()
  }, [])

  return <></>
}

export default Logout;
