import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";
import { auth } from "../Store/Actions/Auth";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../components/Header";
import eye from "../assets/images/eye.svg";
import hide from "../assets/images/hide.svg";
import Button from "../components/Button";
import toast from 'react-hot-toast';

const ResetPassword = () => {
  const [show, setShow] = useState(false);
  const [visibility, setVisibility] = useState(false);

  const [newPassword, setNewPassword] = useState({
    new_password1: "",
    new_password2: "",
    uid: "100",
    token: "a39f778caed2b55463915823fb223945bd6f22ea",
  });
  const history = useHistory();
  const handleNewPasswordChange = (e) => {
    e.preventDefault();
    console.log(newPassword);

    if (newPassword.new_password1 !== newPassword.new_password2) {
      toast("Passwords donot match");
      return;
    }
    const data = JSON.stringify(newPassword);

    var config = {
      method: "post",
      url: "https://curly-forest-26450.botics.co/rest-auth/password/reset/confirm/",
      headers: {
        "Content-Type": "application/json",
      },

      data: data,
    };
    axios(config)
      .then((res) => {
        console.log(res);
        toast("Password has been successfully changed.. Please ok to continue");
        // history.push("/lawhomepage");
        history.push("/resetpass2")
      })
      .catch(() => {
        console.log("WHOOPS");
      });
  };

  const styles = {
    iconStyle: { right: 12, top: 8, position: 'absolute', height: '28px' },
    inputPassword: { position: 'relative', width: '100%', display: 'flex', }
  }

  return (
    <div className="resetpass-root-one">
      <Header />
      <div className="resetpass-root-container rounded-2xl">
        <div className="">
          <h3 className="px-14 pt-6 text-xl font-semibold pb-6 text-center">
            Reset your Password
          </h3>
        </div>
        <form onSubmit={handleNewPasswordChange}>
          <div className="flex flex-col mx-6 space-y-6">
            <p className="text-center">
              Please create new strong password <br /> includes numbers,
              symbols, capital letters.
            </p>

            <div style={styles.inputPassword}>
              <input
                style={{
                  background: "#F1F6FB", width: '100%'
                  // , height: '5vh' 
                }}
                className="pl-2 text-base font-medium py-2"
                placeholder="Create Password"
                required
                type={visibility ? "text" : "password"}
                value={newPassword.new_password1}
                onChange={(e) =>
                  setNewPassword({
                    ...newPassword,
                    new_password1: e.target.value,
                  })
                }
              />
              {visibility ? (
                <img
                  style={styles.iconStyle}
                  src={eye}
                  className="cursor-pointer"
                  onClick={() => setVisibility(false)}
                />
              ) : (
                <img
                  style={styles.iconStyle}
                  src={hide}
                  className="cursor-pointer"
                  onClick={() => setVisibility(true)}
                />
              )}
            </div>

            <div style={styles.inputPassword}>
              <input
                style={{ background: "#F1F6FB", width: '100%' }}
                className="pl-2 text-base font-medium py-2"
                placeholder="Confirm Password"
                type={show ? "text" : "password"}
                required
                value={newPassword.new_password2}
                onChange={(e) =>
                  setNewPassword({
                    ...newPassword,
                    new_password2: e.target.value,
                  })
                }
              />
              {show ? (
                <img
                  style={styles.iconStyle}
                  src={eye}
                  className="cursor-pointer"
                  onClick={() => setShow(false)}
                />
              ) : (
                <img
                  style={styles.iconStyle}
                  src={hide}
                  className="cursor-pointer"
                  onClick={() => setShow(true)}
                />
              )}
            </div>

          </div>
          <div className="flex justify-center pt-5 pb-2">
            {/* <Link to="/resetpass2"> */}
            <Button type="submit">Save Password</Button>
            {/* </Link> */}
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectTo: state.auth.redirectTo,
    accessToken: state.auth.accessToken,
    uid: state.auth.uid,
  };
};

export default connect(mapStateToProps, { auth })(ResetPassword);
