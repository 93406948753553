import React, { useState, useEffect, useContext } from "react";
import Card from "../components/Card";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import icon1 from "../assets/images/settingicon1.png";
import icon2 from "../assets/images/settingicon2.png";
import icon3 from "../assets/images/settingicon3.png";

import "./Settings.css";

const Setting = () => {
  // const history = useHistory();

  // function Logout() {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("user");
  // }

  // const { userType, setUserType } = useContext(TypeContext)
  // useEffect(() => {
  //   if (userType === "") {
  //     history.push("/")
  //   }
  // }, [])

  return (
    <div className="root-one">
      <Header />
      <div className="settings-page-root-container">
        <h2 className="text-2xl font-bold">Settings</h2>
        <hr className="border-yellow-500 border-2 mt-4 mb-6" />

        <div className="grid grid-cols-1 md:grid-cols-2 items-center">
          <div className="links">
            <Card
              imgsrc={icon1}
              label="Privacy Policy"
              buttonLabel="View"
              isSettings={true}
              link="/privacy"
            />
          </div>
          <div className="links">
            <Card
              imgsrc={icon2}
              label="Terms of Service"
              buttonLabel="View"
              isSettings={true}
              link="/terms"
            />
          </div>
          <div className="links">
            <Card
              imgsrc={icon3}
              label="Subscription"
              buttonLabel="View"
              isSettings={true}
              link="/subscription"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
