import axios from "axios";
import Actions from "../Type";

export function loanauth(userObject) {
  return async (dispatch, getState) => {
    const token = getState().auth.accessToken;
    console.log(userObject);
    var data = JSON.stringify(userObject);

    // console.log(data);

    var config = {
      method: "post",
      url: "https://curly-forest-26450.botics.co/rest-auth/login/",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken":
          "ury9FVgfAgG9op3vdFkjKPmB0Y81dccVqLZ9PAvEKrmFf0I8yZjxjF6UgJx6kVJA",
      },

      data: data,
    };

    // console.log(config);

    await axios(config)
      .then(function (response) {
        console.log(response);
        dispatch({
          type: Actions.LOANLOGIN,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        dispatch({
          type: Actions.LOGIN_FAIL,
        });
      });
  };
}
