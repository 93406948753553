import toast from "react-hot-toast";
const API_ENDPOINT = 'https://curly-forest-26450.botics.co';

export const stripePaymentMethodHandler = async (data, cb) => {
     const { pricePlanId, result } = data;
     if (result.error) {
          console.log("error results ==--== ", result.error);
          cb(result);
     } else {
          // commenting for checking the payment source
          const paymentResponse = await stripePayment({
               payment_source: result.paymentMethod.id,
               price_id: pricePlanId
          });
          console.log("checking results ==--== ", result);
          console.log("payment response ==--== ", paymentResponse);

          if (paymentResponse != null) {
               if (typeof (paymentResponse) == "object") {
                    let [key] = Object.keys(paymentResponse)
                    var value = paymentResponse[Object.keys(paymentResponse)[0]];
                    toast(key + " " + value)
               } else {
                    toast("Unable to complete the payment!")
               }
          }
          cb(paymentResponse);

     }
}

// place backend API call for payment
const stripePayment = async data => {
     const accessToken = localStorage.getItem("token")
     const res = await fetch(`${API_ENDPOINT}/api/v1/subscriptions/`, {
          method: 'POST',
          headers: {
               'Content-Type': 'application/json', Authorization: `Token ` + accessToken,
          },
          body: JSON.stringify(data),
     })
     return await res.json();
}