import Header from "../components/Header";
import { Link } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
// import { TypeContext } from "../context/TypeContext";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import { auth } from "../Store/Actions/Auth";
import toast, { Toaster } from 'react-hot-toast';
import Button from "../components/Button";
import ConfigureStore from "../Store/Store";
import eye from "../assets/images/eye.svg";
import hide from "../assets/images/hide.svg";
import "./login.css";

const Login = (props) => {
  const dispatch = useDispatch();

  // const { userType, setUserType } = useContext(TypeContext);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [visibility, setVisibility] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [redirect, setRedirect] = useState(false);

  // const { store } = ConfigureStore();

  const history = useHistory();

  useEffect(() => {
    setInvalid(props.invalidUser);
    if (invalid) {
      return;
    }
  }, [props.invalidUser, invalid]);


  useEffect(() => {
    if (props.accessToken) {
      toast.success("Successfully Logged In")
      history.push("/lawhomepage");
    }
  }, [props.accessToken]);

  //redirect to homepage after login
  useEffect(() => {
    setRedirect(props.redirectTo);
    if (props.redirectTo) {
      history.push(props.redirectTo);
    }
  }, [props.redirectTo, redirect]);

  const LoginCheck = (e) => {
    e.preventDefault();

    let { username, password } = credentials;
    dispatch(
      auth({
        email: username,
        password: password,
      })
    );
  };
  return (
    <div className="root-one">
      <Header />
      <div className="login-root-container rounded-2xl grid grid-cols-1 md:grid-cols-2">
        <div className="">
          <form onSubmit={LoginCheck}>
            <p className="px-14 pt-6 text-lg pb-6 text-center">
              Welcome to Student Loan Portal. <br /> Please Login below
            </p>
            <div className="flex flex-col mx-6 space-y-6">
              <input
                style={{ background: "#F1F6FB" }}
                className="pl-2 text-base rounded-md font-medium py-2"
                placeholder="Enter Email"
                value={credentials.username}
                type="email"
                required
                onChange={(e) =>
                  setCredentials({ ...credentials, username: e.target.value })
                }
              />
              <div style={{
                position: 'relative',
                width: '100%'
              }}>
                <input
                  style={{ background: "#F1F6FB", width: '100%' }}
                  className="pl-2 text-base rounded-md py-2"
                  placeholder="Enter Password"
                  value={credentials.password}
                  required
                  type={visibility ? "text" : "password"}
                  onChange={(e) =>
                    setCredentials({ ...credentials, password: e.target.value })
                  }
                />
                {visibility ? (
                  <img
                    style={{ right: '5px', top: '5px', position: 'absolute', height: '30px' }}
                    src={eye}
                    className="cursor-pointer"
                    onClick={() => setVisibility(false)}
                  />
                ) : (
                  <img
                    style={{ right: '5px', top: '5px', position: 'absolute', height: '28px' }}
                    src={hide}
                    className="cursor-pointer"
                    onClick={() => setVisibility(true)}
                  />
                )}
              </div>
            </div>
            <div className="pt-6 justify-between flex items-center px-4">
              <div>
                <input
                  style={{ background: "#F1F6FB" }}
                  type="checkbox"
                  className="mr-2 w-6 h-5"
                />
                <label>Remember me</label>
              </div>
              <div>
                <p className="text-color">
                  <Link to="/forgot-password" className="links-secondary">
                    Forgot Password?
                  </Link>
                </p>
              </div>
            </div>

            <div className="mx-6 mt-6 mb-6 flex flex-col  justify-center">
              <Button
                type="submit"
                buttonStyle="btn--major--solid"
                buttonSize="btn--small"
              >
                Login
              </Button>
            </div>
          </form>

          <p className="text-center">
            By using Nova Satus you agree to our{" "}
            <span
              className="text-color cursor-pointer"
              onClick={() => history.push("/terms")}
            >
              Terms
            </span>
          </p>
        </div>

        {/* Right */}
        <div className="md:border-l-4">
          <h1 className="text-center font-bold">OR</h1>
          <div className="text-center font-semibold">
            <p className="">
              New Here? <br /> Please Sign Up below.
            </p>
          </div>
          <div className="flex flex-col m-4 md:m-10 space-y-4">
            <Button
              onClick={() => history.push("/signuplaw")}
              type="button"
              buttonStyle="btn--minor--outline"
              buttonSize="btn--medium"
            >
              SIGN UP AS A LAW FIRM
            </Button>

            <Button
              onClick={() => history.push("/signup")}
              type="button"
              buttonStyle="btn--minor--outline"
              buttonSize="btn--medium"
            >
              SIGN UP AS A STUDENT
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectTo: state.auth.redirectTo,
    invalidUser: state.auth.invalidUser,
    accessToken: state.auth.accessToken,
  };
};

export default connect(mapStateToProps, { auth })(Login);
